import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import "../components/global.css"

const SecondPage = () => (
  <Layout>
    <Seo title="About" />

    <div className="bio-mobile-containter">
      <StaticImage
        src="../images/ned-bio.jpg"
        alt="bio"
        placeholder="dominantColor"
        className="bio-mobile"
      />
    </div>
    <div className="bio-container">
      <div className="bio">
        <p>
          Native Seattleite and saltwater junkie, I find creative inspiration
          fishing and cruising the Puget Sound. Equal parts artist and engineer,
          I’m a veteran designer and respected leader of experience design
          teams.
        </p>
      </div>
    </div>

    <p className="intro-text">
      My passion is design system frameworks and visual storytelling. My focus
      is reaching customers through fresh, relevant and memorable brand
      experiences. I bring a great deal of knowledge and craft to every
      engagement, driving creative leadership and scalable design thinking
      across the spectrum of digital media. I want to share my experience,
      challenge my abilities, coach, learn, and contribute to something truly
      remarkable.
    </p>

    <div className="key-areas">
      <h3>
        <span></span>Key Areas of Focus<span></span>
      </h3>
      <div className="areas">
        <div className="area">
          <h4>Design Systems</h4>
          <ul>
            <li>Visual language toolkits</li>
            <li>Brand expression / identity</li>
            <li>Style guide development</li>
            <li>Template engineering</li>
            <li>Brand asset management</li>
          </ul>
        </div>
        <div className="area">
          <h4>Web Interactive</h4>
          <ul>
            <li>Digital storytelling</li>
            <li>Branded content</li>
            <li>Product design</li>
            <li>Learning experience</li>
          </ul>
        </div>
        <div className="area">
          <h4>Team Leadership</h4>
          <ul>
            <li>Design process & operations</li>
            <li>Coaching / mentoring</li>
            <li>Talent acquisition</li>
            <li>Vendor management</li>
            <li>Cross-team strategy</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
